import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "./services/auth.service";
import { Observable, of } from "rxjs";
import { tap, exhaustMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) { }

  canActivate(): any {
    if (!this.auth.isAuth()) {
      window.location.href = "/auth/login";
      return false
    }
    return true;
  }

}
