import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { catchError, min } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { Subject, throwError } from 'rxjs';
@Injectable({
  providedIn: "root",
})
export class HistoryCreditService {
  openModal: Subject<{ open: boolean, content: any }> = new Subject<{ open: boolean, content: any }>();
  constructor(private http: HttpService, private _http: HttpClient) { }

  all() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/lista-solicitud",
    });
  }

  get(identification) {
    return this.http.get("/hdc/resumen/" + identification);
  }

  getHistoryCredit(data: IClient) {
    return this.http.post("/hdc/historia-credito", data);
  }

  getTypeId() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/tipo-ident",
    });
  }

  getGender() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/genero",
    });
  }

  getPaying() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/pagadurias",
    });
  }

  getTypeClient(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/tipo-cliente",
      data,
    });
  }

  getTypeContracts(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/tipo-contrato",
      data,
    });
  }

  getTypeCredit() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/tipo-credito",
    });
  }

  getWallet(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/compra-cartera",
      data,
    });
  }

  getEmials() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/lista-email",
    });
  }

  saveLead(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/save-lead",
      data,
    });
  }

  saveSimulationLead(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/simulacion-lead",
      data,
    });
  }

  autoCompleteRequest(idLead) {
    return this.http.get("/generic/get-simulacion/" + idLead);
  }

  saveStep1(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/save-solicitud",
      data,
    });
  }

  saveStep2(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/save-solicitud-financiera",
      data,
    });
  }

  simulate(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/simular-cuota",
      data,
    });
  }

  simulateV2(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/simular-cuota-v2",
      data,
    });
  }

  simulateV3(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/simular-cuota-v3",
      data,
    });
  }

  saveSimulation(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/save-solicitud-valores",
      data,
    });
  }

  saveWallet(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/add-compra-cartera",
      data,
    });
  }

  saveSeast(numero_solicitud) {
    return this.http.get("/generic/add-seas/" + numero_solicitud);
  }

  getEntityWallet() {
    return this.http.post("/generic/request", {
      resource: "/generic/request/entidades-compra-cartera",
    });
  }

  saveContact(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/validar-solicitud",
      data
    });
  }

  validateBussiness(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/validar-solicitud",
      data
    });
  }

  validateDocuments(data) {
    return this.http.post("/generic/request", {
      resource: "/generic/request/validar-solicitud-firma",
      data
    });
  }

  listRequestData(data) {
    return this.http.post('/generic/request', {
      resource: '/generic/request/lista-solicitud',
      data
    });
  }

  downLoadReport(numeroSolicitud: string) {
    return this.http.get(`/hdc/resumen/${numeroSolicitud}`);
  }

  validateFirm(data) {
    const { idSimulacion, idTransaccion } = data;
    return this.http.get(`/pdf/get-validar-firma-cliente/${idSimulacion}/${idTransaccion}`);
  }

  postSendOtp(cod: any) {
    return this.http.get(`/pdf/enviar-otp-firma/${cod}`);
  }


  prueba() {
    return this.http.get('/pdf/base64');
  }

  getDocuments(data: any) {
    const { id_simulacion, identificacion } = data;
    return this.http.get(`/pdf/get-documentos-generados/${id_simulacion}/${identificacion}`);
  }

  documentsRequired(id) {
    return this.http.get('/file/list-file/' + id);
  }

  viewDocument(data) {
    return this.http.get('/file/download/' + data.numero_solicitud + '/' + data.id, { observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadFile(data) {
    let formData = new FormData();
    formData.append('file', data.document);
    formData.append('numero_solicitud', data.numero_solicitud);
    formData.append('id_file', data.id_file);
    const headers: HttpHeaders = new HttpHeaders({
      'Authentication': String(localStorage.getItem('token')) || null,
      'version': '2.0',
      'apiKey': "db92efc69991",
    });
    const req = new HttpRequest('POST', environment.apiPath + '/file/upload', formData, { headers: headers, reportProgress: true });
    return this._http.request(req);
  }

  deleteFileRequest(data) {
    return this.http.get('/file/delete/' + data.numero_solicitud + '/' + data.id);
  }

  getDepartments() {
    return this.http.get('/login/departamentos');
  }

  getCity(param: any) {
    return this.http.get('/login/municipios/' + param);
  }


  saveADOReponse(data) {
    return this.http.post('/generic/request', { "resource": "/generic/request/save-enrolamiento", data });
  }

  /**
 * @paso 1
 */
  validateClientADO(id) {
    const options = {
      headers: {
        apiKey: "db92efc69991"
      }
    }
    return this.http.get('https://adocolombia-qa.ado-tech.com/FinexusQA/api/finexusqa/FindByNumberId?identification=' + id + '&docType=1&returnImages=false', options).pipe(
      catchError(err => {
        console.log(err.error);
        console.log(err.error.error);
        alert('DESDE EL SERVICE' + err.error.error);
        return throwError(err);
      })
    );

  }
  /**
  * @paso2: se Valida la ultima transaccion
  */
  validateClientADOByIdTrasanction(id) {
    const options = {
      headers: {
        apiKey: "db92efc69991"
      }
    }
    return this.http.get('https://adocolombia-qa.ado-tech.com/FinexusQA/api/FinexusQA/Validation/' + id + '?returnImages=false', options);
  }

  validateClientSuccess(id) {
    const options = {
      headers: {
        apiKey: "db92efc69991"
      }
    }
    return this.http.get('https://adocolombia-qa.ado-tech.com/FinexusQA/api/finexusqa/FindByNumberIdSuccess?identification=' + id + '&docType=1&returnImages=false&enrol=true', options);
  }


  listFiles(numero_solicitud) {
    return this.http.get("/file/list-file/" + numero_solicitud);
  }

  deleteFile(numero_solicitud, id_archivo) {
    return this.http.get("/file/delete/" + numero_solicitud + "/" + id_archivo);
  }

  viewFile(numero_solicitud, id_archivo) {
    window.open(
      environment.apiPath +
      "/file/download/" +
      numero_solicitud +
      "/" +
      id_archivo,
      "_blank"
    );
  }


  buildDate(object) {
    if (!(object.year && object.month && object.day)) {
      return "";
    }

    let month = String(object.month).length == 1 ? "0" + String(object.month) : String(object.month)
    let day = String(object.day).length == 1 ? "0" + String(object.day) : object.day

    console.log(object.year + "-" + month + "-" + day)
    return object.year + "-" + month + "-" + day
  }

  get currentClient() {
    return JSON.parse(localStorage.getItem("client"));
  }

  set currentClient(client) {
    localStorage.setItem("client", JSON.stringify(client));
  }

  get wallets() {
    return JSON.parse(localStorage.getItem("wallets"));
  }

  set wallets(data) {
    localStorage.setItem("wallets", JSON.stringify(data));
  }
}

export interface IClient {
  tipo_identificacion: string;
  identificacion: string;
  primer_apellido: string;
  empresa: string;
  usuario: string;
}
