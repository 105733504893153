import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCreditComponent } from '../request-credit/request-credit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {SimulatorComponent} from "../simulator/simulator.component";



@NgModule({
  declarations: [
    RequestCreditComponent,
    SimulatorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [RequestCreditComponent, SimulatorComponent]
})
export class SharedModule { }
