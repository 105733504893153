import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpService) { }
  loginAdmin(data: iLogin) {
    const header = new HttpHeaders();
    header.set('content-type', 'application/json');
    header.set('content-type', 'application/json');

    return this.http.post('/login/signin', data, { header });
  }

  changePassword(userData: any) {
    const header = new HttpHeaders();
    header.set('content-type', 'application/json');
    header.set('content-type', 'application/json');

    return this.http.post('/login/cambiar-password', userData, {header});
  }


  login(data: iLogin) {
    console.log('LOGIN')
    return this.http.post('/login/signin', data)
  }

  idValidate() {
    return this.http.post('/generic/request', { "resource": "/generic/request/valida-identidad" });
  }

  get user() {
    return JSON.parse(localStorage.getItem('user'))
  }

  set user(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }


  get token() {
    return localStorage.getItem('token')
  }

  isAuth() {
    const token = localStorage.getItem('token');
    return token != null;
  }

  public get name(): string {
    return String(localStorage.getItem('name'));
  }

  set token(token) {
    localStorage.setItem('token', token)
  }

  get cleanLocal() {
    return localStorage.clear();
  }
}




export interface iLogin {
  username: string;
  password: string;
}
