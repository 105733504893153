import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-simulator",
  templateUrl: "./simulator.component.html",
  styleUrls: ["./simulator.component.scss"],
})
export class SimulatorComponent implements OnInit {
 

  constructor() {}

  ngOnInit() {}
}
