// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiPath: "https://finexus.com.co:8443/finexus/api",
  //apiPath: "https://eskrun.ngrok.io/finexus/api",
  //apiPathDeceval: "https://eskrun.ngrok.io/deceval/api",
  apiPathDeceval: "https://finexus.com.co:8443/deceval/api",
  empresa: "FINEXUS"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
